import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.chartData.hasLastEntry)?_c(VCardTitle,{staticClass:"mt-n8"},[(_vm.chartData.isCurrency)?_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.chartData.lastEntry.value))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.chartData.lastEntry.value)+" ")]),(_vm.chartData.hasSecondToLastEntry)?_c('span',{staticClass:"subtitle-1 text--secondary ml-1"},[_vm._v(" from "+_vm._s(_vm.chartData.isCurrency ? _vm.toKCurrency(_vm.chartData.secondToLastEntry.value) : _vm.chartData.secondToLastEntry.value)+" ")]):_vm._e(),_c(VSpacer),(_vm.chartData.deltaIsGreen)?_c(VIcon,{attrs:{"small":"","color":"success"}},[_vm._v(" "+_vm._s(_vm.upIcon)+" ")]):_vm._e(),(_vm.chartData.deltaIsRed)?_c(VIcon,{attrs:{"small":"","color":"error"}},[_vm._v(" "+_vm._s(_vm.downIcon)+" ")]):_vm._e(),_c('h6',{staticClass:"text-h6",class:{
            'error--text': _vm.chartData.deltaIsRed,
            'success--text': _vm.chartData.deltaIsGreen,
        }},[_vm._v(" "+_vm._s(_vm.chartData.deltaPercentage)+" ")])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }