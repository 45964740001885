import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardSubtitle,{staticClass:"overline"},[_vm._v(" Test Orders ")]),(
            !_vm.chartIsLoading && _vm.lineChartData && _vm.lineChartData.hasLastEntry
        )?_c(VCardTitle,{staticClass:"mt-n8"},[_vm._v(" "+_vm._s(_vm.lineChartData.lastEntry.value)+" "),(_vm.lineChartData.hasSecondToLastEntry)?_c('span',{staticClass:"subtitle-1 text--secondary ml-1"},[_vm._v(" from "+_vm._s(_vm.lineChartData.secondToLastEntry.value)+" ")]):_vm._e(),_c(VSpacer),(_vm.lineChartData.deltaIsGreen)?_c(VIcon,{attrs:{"small":"","color":"success"}},[_vm._v(" "+_vm._s(_vm.upIcon)+" ")]):_vm._e(),(_vm.lineChartData.deltaIsRed)?_c(VIcon,{attrs:{"small":"","color":"error"}},[_vm._v(" "+_vm._s(_vm.downIcon)+" ")]):_vm._e(),_c('h6',{staticClass:"text-h6",class:{
                'error--text': _vm.lineChartData.deltaIsRed,
                'success--text': _vm.lineChartData.deltaIsGreen,
            }},[_vm._v(" "+_vm._s(_vm.lineChartData.deltaPercentage)+" ")])],1):_vm._e(),_c(VCardText,[(_vm.chartIsLoading)?_c('loading-spinner'):_vm._e(),(!_vm.chartIsLoading && _vm.lineChartData)?_c('line-chart',{attrs:{"height":200,"dataset-label":_vm.lineChartData.title,"y-step-size":15,"chartData":_vm.lineChartData}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }