import { AxiosResponse } from "axios";
import Axios from "./axios";

class DashboardCharts {
    private axios: typeof Axios;

    constructor(axios: typeof Axios) {
        this.axios = axios;
    }

    show(chartId: string): Promise<AxiosResponse> {
        return this.axios.get("/admin/dashboard-charts/" + chartId);
    }
}

export default new DashboardCharts(Axios);
