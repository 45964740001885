import KeyValuePair, {
    SerializedKeyValuePair,
} from "@/value-objects/key-value-pair";

export type SerializedTimeValuePair = SerializedKeyValuePair<string, number>;

export class TimeValuePair extends KeyValuePair<Date, number> {
    constructor(data: SerializedTimeValuePair) {
        super(new Date(data.key), data.value);
    }
}
