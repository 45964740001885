export interface SerializedLineChartData<T> {
    title: string;
    series: Array<T>;
    is_time_series: boolean;
    is_currency: boolean;
    delta: number;
    timezone?: string;
}

export default class LineChartData<T> {
    private readonly _title: string;
    private readonly _series: Array<T>;
    private readonly _isTimeSeries: boolean;
    private readonly _isCurrency: boolean;
    private readonly _delta: number;
    private readonly _timezone: string;

    constructor(
        title: string,
        series: Array<T>,
        isTimeSeries: boolean,
        isCurrency: boolean,
        delta: number,
        timezone?: string
    ) {
        this._title = title;
        this._series = series;
        this._isTimeSeries = isTimeSeries;
        this._isCurrency = isCurrency;
        this._delta = delta;
        this._timezone = timezone
            ? timezone
            : Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    get timezone(): string {
        return this._timezone;
    }

    get delta(): number {
        return this._delta;
    }

    get title(): string {
        return this._title;
    }

    get series(): Array<T> {
        return this._series;
    }

    get isTimeSeries(): boolean {
        return this._isTimeSeries;
    }

    get isCurrency(): boolean {
        return this._isCurrency;
    }

    get hasSecondToLastEntry(): boolean {
        return this.secondToLastEntry !== undefined;
    }

    get secondToLastEntry(): T | undefined {
        if (this.series.length > 1) {
            return this.series[this.series.length - 2];
        }
        return undefined;
    }

    get hasLastEntry(): boolean {
        return this.lastEntry !== undefined;
    }

    get hasDelta(): boolean {
        return this.delta !== 0;
    }

    get deltaIsGreen(): boolean {
        return this.delta >= 0;
    }

    get deltaIsRed(): boolean {
        return this.delta < 0;
    }

    get deltaIsNeutral(): boolean {
        return this.delta == 0;
    }

    get deltaPercentage(): string {
        if (this.delta < 0) {
            return (this.delta * -100).toFixed(1) + "%";
        }
        return (this.delta * 100).toFixed(1) + "%";
    }

    get lastEntry(): T | undefined {
        if (this.series.length > 0) {
            return this.series[this.series.length - 1];
        }
        return undefined;
    }
}
