import {
    SerializedTimeValuePair,
    TimeValuePair,
} from "@/value-objects/time-value-pair";
import LineChartData, {
    SerializedLineChartData,
} from "@/value-objects/line-chart-data";

export type SerializedTimeSeriesLineChartData =
    SerializedLineChartData<SerializedTimeValuePair>;

export default class TimeSeriesLineChartData extends LineChartData<TimeValuePair> {
    constructor(data: SerializedTimeSeriesLineChartData) {
        const series = data.series.map(
            (keyValuePair: SerializedTimeValuePair) => {
                return new TimeValuePair(keyValuePair);
            }
        );
        super(
            data.title,
            series,
            data.is_time_series,
            data.is_currency,
            data.delta,
            data.timezone
        );
    }
}
